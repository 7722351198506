// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import 'expose-loader?jQuery!jquery' // eslint-disable-line
import 'expose-loader?$!jquery' // eslint-disable-line
import Vue from 'vue'
import BootstrapVue from 'bootstrap-vue'
import VueTouch from 'vue-touch'
import Trend from 'vuetrend'
import jQuery from 'jquery'

import store from './store'
import router from './Routes'
import App from './App'
import io from 'socket.io-client'
import moment from 'moment-timezone'
moment.tz.setDefault('America/Santiago')
import Vue2Filters from 'vue2-filters'
import 'vue-loading-overlay/dist/vue-loading.css'
import Swal from 'sweetalert2'
import vSelect from 'vue-select'

// import "vue-select/src/scss/vue-select.scss";
import 'vue-select/dist/vue-select.css'

import './registerServiceWorker'


var Vue2FiltersConfig = {
  currency: {
    symbol: '$',
    decimalDigits: 0,
    thousandsSeparator: '.',
    decimalSeparator: ',',
    symbolOnLeft: true,
    spaceBetweenAmountAndSymbol: true
  },
}

const swalWithBootstrapButtons = Swal.mixin({
  confirmButtonClass: 'btn btn-warning',
  cancelButtonClass: 'btn btn-danger mr-1',
  buttonsStyling: false,
  reverseButtons: true,
  allowEnterKey:false,
  showCancelButton: true,
  confirmButtonText: 'Si, eliminar',
  cancelButtonText: 'No, cancelar!!',
  type: 'error',
})

const Toast = Swal.mixin({
  toast: true,
  position: 'top-end',
  showConfirmButton: false,
  timer: 3000
})

Vue.use(BootstrapVue);
Vue.use(VueTouch);
Vue.use(Trend);
Vue.use(Vue2Filters, Vue2FiltersConfig)
Vue.component('v-select', vSelect)

Vue.filter('formatData',function (value) {
  return moment(value, 'YYYY-MM-DD').format('DD-MM-YYYY')
})

// Vue.prototype.$Base_url = window.location.origin + '/api/'
Vue.prototype.$Base_url = 'https://venta.macoda.cl/api/'
Vue.prototype.$socket = io('https://socket.dafda.cl/')
Vue.prototype.$JQuery = jQuery
Vue.prototype.$Toast = Toast
Vue.prototype.$Notific = swalWithBootstrapButtons

Vue.config.productionTip = true

/* eslint-disable no-new */
new Vue({
  el: '#app',
  store,
  router,
  render: h => h(App),
});
